.btn{
  display: inline-block;
  margin-bottom: 0;
  border: 0;
  background-image: none;
  vertical-align: middle;
  text-align: center;
  text-decoration: none!important;
  white-space: nowrap;
  font-weight: 400;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transition: border .25s ease,box-shadow .25s ease,background .25s ease;
  padding: 11.5px 1rem;
  font-size: var(--btn-font-size);
  line-height: var(--btn-line-height);
  border-radius: var(--btn-border-radius);
  text-transform: var(--btn-text-transform);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all .25s ease;

  &[disabled]{
    cursor: not-allowed;
    opacity: .6;
  }

  &:focus{
    outline-color: $main-color;
  }

}

.btn-dark{
  color: $light;
  background-color: $main-dark;
  &:hover:not([disabled]){
    @extend %lightenBg;
  }
}
.btn-light{
  color: $main-color;
  background-color: $body-bg-color;
  box-shadow: inset 0 0 0 1px $main-color;
  &:hover:not([disabled]){
    // @extend %darkenBg;
    box-shadow: inset 0 0 0 2px $main-color;
  }
}

.btn-clean{
  display: inline-block;
  margin: 0;
  border: 0;
  background-image: none;
  background-color: transparent;
  text-align: center;
  text-decoration: none!important;
  white-space: normal;
  font-weight: inherit;
  cursor: pointer;
  padding:0;
  font-size: inherit;
  line-height: inherit;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}