/* *********************
* Sizing
***********************/

$sizes: ( 
  "4":0.25rem,
  "8":0.5rem,
  "16":1rem,
  "24":1.5rem,
  "32":2rem,
  "40":2.5rem,
  "48":3rem,
  "56":3.5rem,
  "64":4rem,
  "72":4.5rem,
  "80":5rem,
  "88":5.5rem,
  "96":6rem,
);

@mixin padding() {
  @each $name,
  $size in $sizes {
    .p-#{$name} {
      padding: map-get($sizes, $name)!important;
    }
		.p-t-#{$name} {
      padding-top: map-get($sizes, $name)!important;
    }
		.p-r-#{$name} {
      padding-right: map-get($sizes, $name)!important;
    }
		.p-b-#{$name} {
      padding-bottom: map-get($sizes, $name)!important;
    }
		.p-l-#{$name} {
      padding-left: map-get($sizes, $name)!important;
    }
  }
  .p-0{
    padding: 0!important;
  }
  .p-t-0{
    padding-top: 0!important;
  }
  .p-r-0{
    padding-right: 0!important;
  }
  .p-b-0{
    padding-bottom: 0!important;
  }
  .p-l-0{
    padding-left: 0!important;
  }
}

@mixin margin() {

  @each $name,
  $size in $sizes {
    .m-#{$name} {
      margin: map-get($sizes, $name)!important;
    }
    .m-t-#{$name} {
      margin-top: map-get($sizes, $name)!important;
    }
		.m-r-#{$name} {
      margin-right: map-get($sizes, $name)!important;
    }
		.m-b-#{$name} {
      margin-bottom: map-get($sizes, $name)!important;
    }
		.m-l-#{$name} {
      margin-left: map-get($sizes, $name)!important;
    }
  }
  .m-0{
    margin: 0!important;
  }
  .m-t-0{
    margin-top: 0!important;
  }
  .m-r-0{
    margin-right: 0!important;
  }
  .m-b-0{
    margin-bottom: 0!important;
  }
  .m-l-0{
    margin-left: 0!important;
  }
  .m-auto{
    margin: auto!important;
  }
  .m-t-auto{
    margin-top: auto!important;
  }
  .m-r-auto{
    margin-right: auto!important;
  }
  .m-b-auto{
    margin-bottom: auto!important;
  }
  .m-l-auto{
    margin-left: auto!important;
  }
}
