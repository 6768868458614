.iot-badge{
  transform-origin: 50%;
  transform: rotate(-15deg);

  .outer-text{
    animation-duration: 3s;
    animation-fill-mode: backwards;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    //animation-name: rotates;
    transform-origin: 50%;
    animation-timing-function: ease-in-out;
  }
}


@-webkit-keyframes rotates{
  from {
    transform: rotate(-35deg);
  }

  to {
    transform: rotate(35deg);
  }
}