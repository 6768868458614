@include padding();
@include margin();

.separator--bottom{
  border-bottom: 1px solid var(--core-dark-15);
}
.separator--top{
  border-top: 1px solid var(--core-dark-15);
}

.img-circle{
  overflow:hidden;
  border-radius: 50%;
}

img{
  max-width: 100%;
  height: auto;
}

// Displays

.d-flex{
  display: flex;
  &--col{
    display: flex;
    flex-direction: column;
  }
  &--lg{
    @media(min-width: $grid-breakpoint-lg){
      display: flex;
    }
  }
}

.flex-wrap{
  flex-wrap: wrap;
}
.d-block{
  display: block;
}
.d-grid{
  display: grid;
}
.d-inline{
  display: inline;
}
.d-contents{
  display: contents;
}
.d-inline-block{
  display: inline-block;
}

.align-start{
  align-items: flex-start;
}
.align-center{
  align-items: center;
}
.align-end{
  align-items: flex-end;
}
.align-stretch{
  align-items: stretch;
}

.justify-start{
  justify-content: flex-start;
}
.justify-center{
  justify-content: center;
}
.justify-end{
  justify-content: flex-end;
}
.justify-stretch{
  justify-content: stretch;
}


// grids
@media(min-width: $grid-breakpoint-md){
  .grid-col--1-11{
    grid-template-columns: 1fr 11fr;
  }
  .grid-col--2-10{
    grid-template-columns: 2fr 10fr;
  }
  .grid-col--3-9{
    grid-template-columns: 3fr 9fr;
  }
  .grid-col--4-8{
    grid-template-columns: 4fr 8fr;
  }
  .grid-col--5-7{
    grid-template-columns: 5fr 7fr;
  }
  .grid-col--6-6{
    grid-template-columns: 6fr 6fr;
  }
  .grid-col--7-5{
    grid-template-columns: 7fr 5fr;
  }
  .grid-col--8-4{
    grid-template-columns: 8fr 4fr;
  }
  .grid-col--9-3{
    grid-template-columns: 9fr 3fr;
  }
  .grid-col--10-2{
    grid-template-columns: 10fr 2fr;
  }
  .grid-col--11-1{
    grid-template-columns: 11fr 1fr;
  }

  .grid-col--4-4-4{
    grid-template-columns: 4fr 4fr 4fr;
  }

  .gap-16{
    grid-gap: 1rem;
  }
  .gap-24{
    grid-gap: 1.5rem;
  }
  .gap-32{
    grid-gap: 2rem;
  }
  .gap-40{
    grid-gap: 2.5rem;
  }
}

// Lighten
%lightenBg{
  background-image: linear-gradient(0, rgba(255,255,255,.25), rgba(255,255,255,.25));
}
// Darken
%darkenBg{
  background-image: linear-gradient(0, rgba(0,0,0,.1), rgba(0,0,0,.1));
}




.w-100{
  width: 100%;
}

.font-vw{
  font-size: clamp(2.5rem, 3.4vw, 3.8rem);
  letter-spacing: -0.03em;
}


.center-block{
  display: block;
  margin: 0 auto;
}