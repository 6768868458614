.hero-list{
  padding-bottom: 2rem;
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      position: relative;
      // font-size: 1.25rem;
      letter-spacing: -0.05em;
      padding: 1rem 0 1rem;
      // margin-bottom: 0.5rem;
    }
  }
}

.highlights-list{
  padding-top: 3rem;

  &__item{
    display: block;
    padding: 0;
    list-style: none;
    margin: 0;
    &__img{
      max-width: 120px;
      display: block;
      margin: 0 auto;
      img{
        max-height: 100px;
      }
    }
    h4{
      margin-bottom: 1rem;
      color: $main-dark;
      font-size: 1.4rem;
      text-align: center;
    }
    &__content{
      font-size: 1rem;
    }
  }

}


.list-unstyled{
  margin: 0;
  padding: 0;
  list-style: none;
}


@media(min-width: $grid-breakpoint-md){
  .highlights-list{
    padding-top: 3rem;
    padding-bottom: 6rem;
    &__item{
      display: block;
      padding: 0;
      list-style: none;
      margin: 0;
      // padding: 3rem 0;
      // grid-gap: 2rem;
      // grid-template-columns: 2fr 6fr 4fr;
      // grid-template-areas: "no body img";
      
      &__content{
        h4{
          margin-bottom: 1rem;
          color: $main-dark;
        }
      }
      // &:nth-child(odd){
      //   grid-template-columns: 4fr 6fr 2fr;
      //   grid-template-areas: "img body no";
      // }
    }
  }






  .hero-list{
    padding-bottom:0 ;
    ul{
      margin-top: 2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li{
        // padding-left: 2rem;
        // font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }
}