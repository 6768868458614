body {
  font-family: $font-family;
  line-height: 1.5;
  letter-spacing: -0.03em;
  font-size: 1.25rem;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  color: $main-dark;
  > small {
    font-size: 65%;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5 {
  display: inline;
}

h1,
.h1 {
  font-size: $h1;
  line-height: $h1-line-height;
}
h2,
.h2 {
  font-size: $h2;
  line-height: $h2-line-height;
}
h3,
.h3 {
  font-size: $h3;
  line-height: $h3-line-height;
}
h4,
.h4 {
  font-size: $h4;
  line-height: $h4-line-height;
}
h5,
.h5 {
  font-size: $h5;
  line-height: $h5-line-height;
  font-weight: normal;
  font-style: italic;
  color: $main-dark;
  margin: 0 0 2rem;
}

p {
  margin: 0 0 1rem;
}

.lead{
  font-size: 1.2rem;
  font-weight: lighter;
}

.text-pre{
  white-space: pre-wrap;
}

small, .small {
  font-size: 75%;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-right-sm {
  @media(min-width: $grid-breakpoint-sm){
    text-align: right;
  }
}

.text-muted {
  color: $text-muted;
}

.text-fade {
  opacity: 0.75;
}

.text-14 {
  font-size: 0.875rem;
  line-height: 1.3;
}

.text-uppercase{
  text-transform: uppercase;
}

.text-truncate{
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a{
  color: $link-color;
  text-decoration: none;
  &:hover, &:focus{
    text-decoration: underline;
  }
  &:focus{
    outline: 2px solid $main-color;
  }
}

ul{
  padding-left: 1em;
}


.label{
  color: var(--tech-color);
  background: var(--tech-bg);
  padding: .2rem .5rem;
  border-radius: 2px;
}

.text-light{
  font-weight: lighter;
}
.text-normal{
  font-weight: 400;
}
.text-bold{
  font-weight: 600;
}

.text-underline{
  text-decoration: underline;
}

.gray-text{
  color: var(--gray-text);
}

.first-large{
  p:first-child{
    font-size: 1.75rem;
    font-weight: lighter;
  }
}
.lead{
  font-size: 1.75rem;
}


.text-white{
  color: $light!important;
}
.text-dark{
  color: $main-dark!important;
}
.text-color{
  color: $main-color!important;
}
