// COLORS
:root{
  --main-color:                     #03a9f4;
  --main-light:                     #FFE400;
  --main-dark:                      #032858;
  --light:                           white;
  --body-bg-color:                   var(--light);

  --gray-1:            #40576E;
  --gray-2:            #738594;
  --gray-3:            #A6B0BA;
  --gray-4:            #D9DEE3;
  --gray-5:            #f5f5f5;
  --gray-6:            #f9f9f9;
  --gray-text:          #212121;


  --link-color:                     var(--main-color);




  --font-family:                     'Work Sans', "Helvetica", Arial, sans-serif;
  --font-family-mono:                Menlo, Monaco, Consolas, "Courier New", monospace;

  --btn-font-size:                    .875rem;
  --btn-line-height:                   1;
  --btn-border-radius:                .25rem;
  --btn-text-transform:                uppercase;

  --terminal-border-radius:            8px;
  --terminal-bg:                       #181b2b;
  --terminal-color:                    var(--light);
  --terminal-text-subtle:             var(--gray-3);
}

$body-bg-color:                     var(--body-bg-color);
$main-color:                        var(--main-color);
$main-light:                        var(--main-light);
$main-dark:                         var(--main-dark);
$light:                             var(--light);

$text-muted:                        var(--gray-3);
$link-color:                        var(--link-color);

$font-family:                       var(--font-family);
$font-family-mono:                  var(--font-family-mono);


$large-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
0 6.7px 5.3px rgba(0, 0, 0, 0.028),
0 12.5px 10px rgba(0, 0, 0, 0.035),
0 22.3px 17.9px rgba(0, 0, 0, 0.042),
0 41.8px 33.4px rgba(0, 0, 0, 0.05),
0 100px 80px rgba(0, 0, 0, 0.07);

$medium-shadow: 0 2.9px 3px rgba(0, 0, 0, 0.04),
0 23px 24px rgba(0, 0, 0, 0.08);


/*
* Grid
***********/
$grid-breakpoint-lg: 1280px;
$grid-breakpoint-md: 992px;
$grid-breakpoint-sm: 768px;



/*
* Typography
***********/
$h1: 4.209rem;
$h2: 3.157rem;
$h3: 2.369rem;
$h4: 1.777rem;
$h5: 1.333rem;
$h1-line-height: 1.1;
$h2-line-height: 1.1;
$h3-line-height: 1.2;
$h4-line-height: 1.2;
$h5-line-height: 1.2;

/*
* Buttons
***********/
