
.main-nav{
  position: sticky;
  top: 0;
  z-index: 90;
  background-color: var(--body-bg-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar{
  @extend .container;
  display: flex;
  align-items: center;
  position: relative;
  .brand-top{
    max-width: 120px;
    display: block;
    flex-basis: 120px;
    height: 40px;
    .btn-clean{
      width: 100%;
      max-height: 40px;
      &:focus{
        outline: none;
      }
    }
  }
}

.shadow-nav{
  height: 0;
  position: relative;
  z-index: 80;
  overflow: visible;
  position: sticky;
  top: 56px;
  transform: translate(0, -56px);
  clip-path: polygon(0 56px, 100% 56px, 100% 320px, 0 320px);
  &:before{
    content:'';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 56px;
    background: $body-bg-color;
    box-shadow: $medium-shadow;
  }
}


.navbar__btn--toggle{
  @extend .btn;
  position: relative;
  flex-grow: 1;
  margin-left: 2rem;
  height: 42px;
  display: flex;
  align-items: center;
  background-color: $body-bg-color;
  box-shadow: inset 0 0 0 2px $main-color;
  &:before{
    content: 'MENU';
    font-size: 14px;
  }
  &:after{
    content: '⌃';
    display: inline-block;
    transform: rotate(180deg);
    margin-left: auto;
    font-size: 1.3em;
    transition: transform .25s ease;
    line-height: 0.8;
    height: 10px;
  }
  .nav-open &{
    &:after{
      transform: rotate(0);
    }
  }
}


.navbar__menu{
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 50px;
  background-color: $body-bg-color;
  max-height: 0;
  overflow: hidden;
  box-shadow: 0 7px 12px rgba(0,0,0,.1);
  transition: all .55s ease-in-out;
  .nav-open &{
    max-height: calc(100vh - 60px);
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}

.navbar__item{
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  
  > a{
    display: block;
    padding: 1.5rem 1rem;
    text-align: center;
    color: $main-dark;
    font-size: 0.875rem;
    &.active{
      color: $main-color;
    }
    &:focus{
      text-decoration: none;
    }
  }
}

@media(min-width: $grid-breakpoint-md){

  

  .main-nav{
    box-shadow: unset;
  }
  .navbar__btn--toggle{
    display: none;
  }
  .navbar__menu{
    flex-direction: row;
    overflow: visible;
    position: static;
    max-height: unset;
    box-shadow: none;
    padding: 0!important;
  }
  .navbar__item > a{
    padding: .5rem 1rem;
  }
}