html {
  scroll-behavior: smooth;
}

*{
  box-sizing: border-box;
}

body{
  color: var(--gray-text);
  background-color: $body-bg-color;
}


.container{
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1.5rem;
  @media(min-width: $grid-breakpoint-lg){
    padding: 0;
  }
}

.home-content{
  position: relative;
  z-index: 100;
  // background-color: $body-bg-color;
}

.page-section{
  // min-height: 100vh;
  padding-top: 2rem;
  padding-bottom: 2rem;
  h2{
    margin-top: 0;
  }
  &#how-it-works{
    background-color: var(--gray-4);
    clip-path: polygon(0 40px, 100% 0, 100% 100%, 0 calc(100% - 20px));
  }
  &#contribute{
    background-color: $main-light;
    min-height: unset;
    clip-path: polygon(0 40px, 100% 0, 100% 100%, 0 calc(100% - 20px));
    .text-block{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }
    .d-flex > a{
      display: flex;
      flex: 1 0 220px;
      margin: 1.5rem;
      align-items: center;
      justify-content: center;
      img{
        max-height: 65px;
        max-width: 220px;
        height: auto;
        display: inline-block;
      }
    }
    a > p{
      display: contents;
    }
  }
}

.img-container{
  margin-bottom: 2rem;
}


.top-head{
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  padding: .5rem 0;
  .brand{
    width: 140px;
  }
  --link-color: var(--gray-text);
}


.single-page{
  padding: 5rem 1rem;
}


@media(min-width: $grid-breakpoint-sm){
  .container{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}


@media(min-width: $grid-breakpoint-md){
  .single-page{
    padding: 3rem 0 5rem;
  }
  .text-block{
    max-width: 870px;
    margin: 0 auto;
  }
}



.main-footer{
  padding: 1rem;
  background-color: $main-dark;
  color: $light;
  --link-color: var(--light);
  > *{
    opacity: .75;
  }
  p{
    font-size: 12px;
    margin: 0;
  }
}

.social-menu{
  padding:0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  a{ 
    color: $light;
    &:hover{
      color: $link-color;
    }
  }
  li {
    padding: .5rem;
    svg{
      height: 24px;
      fill: currentColor;
    }
  }
}

.banner{
  background-color: $main-light;
  margin-top: -3rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  .d-flex{
    flex-wrap: wrap;
    h3{ 
      flex-basis: 100%;
      text-align: center;
    }
    p, div{
      flex-basis: 100%;
    }
    @media(min-width: $grid-breakpoint-md){
      p, div{ flex-basis: 50%;}
    }
  }
}

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	transition: opacity 500ms;
	visibility: hidden;
	opacity: 0;
}
.overlay:target {
	visibility: visible;
	opacity: 1;
}
.popup {
	margin: 30px auto;
	padding: 15px;
	border-radius: 5px;
	width: 49%;
	position: absolute;
	left: 35%;
	/* transition: all 5s ease-in-out; */
}
.popup h2 {
	margin-top: 0;
	color: #333;
	font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
	position: absolute;
	top: 35px;
	left: 25px;
	background: #00495d;
	transition: all 200ms;
	font-size: 30px;
	font-weight: bold;
	text-decoration: none;
	color: #fff;
	padding: 0px 5px;
}
.search {
	position: absolute;
	top: 19px;
	left: 45%;
	/* background: #ce00ff; */
	transition: all 200ms;
	font-size: 30px;
	font-weight: bold;
	text-decoration: none;
	color: #000;
	padding: 0px 5px;
}

.popup .close:hover {
	color: #ffffff;
}
.popup .content {/*	max-height: 30%;
*/overflow: auto;border-top: 2px dashed #d1d6d8;margin-top: 16px;}
.div1 {
	width: 50%;
	padding: 20px;
	/* float: left; */
	height: auto;
	background:#fff;
	border-radius: 25px;
}


.content h3 { text-align: center;
    margin: 15px 0 -1px 0;
    text-transform: uppercase;
    color: #00495d;
}
@media screen and (max-width: 1024px) {
.box {
	width: 70%;
}
.popup {
	width: 70%;
	left:20%;
}
.div1 {
	width: 70%;
	padding: 20px;
	float: left;
}

}

/* sticky button */

#feedback1 {
    height: 0px;
    width: 85px;
    position: fixed;
    right: 0;
    top: 30%;
    z-index: 1000;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

#feedback1 a {
	display: block;
	background:url(pc.png) no-repeat;
	height: 52px;
	width: 155px;
	color: #fff;
	font-family: Arial, sans-serif;
	font-size: 17px;
	font-weight: bold;
	text-decoration: none;

}
#feedback1 a:hover {
	background:url(pc-over.png) no-repeat;
}

#feedback {
	height: 0px;
	width: 85px;
	position: fixed;
	right: 0;
	top: 50%;
	z-index: 1000;
	transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
#feedback a {
	display: block;
	background:#000;
	height: 52px;
	padding-top: 10px;
	width: 155px;
	text-align: center;
	color: #fff;
	font-family: Arial, sans-serif;
	font-size: 17px;
	font-weight: bold;
	text-decoration: none;
}
#feedback a:hover {
	background:#00495d;
}

/* enquiry form */

#quickenquire input[type=text], input[type=email], textarea, select {
	width: 100%;
	padding: 5px 15px;
	margin: 8px 0;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}


#quickenquire label {
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	padding: 0px!important;
	margin: 0px!important;
}
#quickenquire  input[type=submit] {
    width: 100%;
    background-color: #00495d;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 7px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

#quickenquire  input[type=submit]:hover {
    background-color: #000000;
}