.particles-container{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  opacity: .5;
}

.top-section{
  --link-color: currentColor;
  position: relative;
  z-index: 50;
  // background: $main-color;
  // background: radial-gradient(circle at 50% 50%, #03a9f4, #0f95dd, #1381c6, #146eb0, #125b99, #0f4983, #09386d, #032858);
  background: radial-gradient(circle at 50% 50%, #03a9f4, #05a6f0, #0b9ce6, #108fd7, #137fc4, #146daf, #125b9a, #0f4b85, #0b3d73, #073265, #042b5b, #032858);
  padding: 8rem 0 4rem;
  color: $light;

  .the-right-way-logo{
   max-width: 360px;
   margin: 1rem auto;
   display: block;
   pointer-events: none;
   position: relative;
  }
  @media(min-width: $grid-breakpoint-md){
    
    padding: 8rem 0 6rem;
    clip-path: polygon(0 0, 100% 69px, 100% 100%, 0 calc(100% - 20px));
  }  
}

.site-title{
  max-width: 58ch;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}