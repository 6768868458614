.post-list{
  margin: 0 auto;
  padding: 0;
  list-style: none;
  max-width: calc(70ch + 270px);
  &__item{
    padding: 2rem 0;
    border-bottom: 1px solid var(--gray-6);
    h2, h3,h4, h6{
      margin: 0;
    }
    h4{
      margin-bottom: 1rem;
    }
    h6{
      color: var(--main-color);
    }
  }

  &__item__image{
    min-width: 270px;
    width: 270px;
    margin: 0 auto;
  }

}

@media(min-width: $grid-breakpoint-sm){
  .post-list{
    &__item{
      display: flex;
    }
    &__item__body{
      padding: 0 0 0 2rem;
    }
  }
}