.terminal{
  // min-height: 500px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: var(--terminal-border-radius);
  box-shadow: $large-shadow;
  position: relative;

  &__header{
    background-color: var(--gray-5);
    min-height: 30px;
    border-top-left-radius: var(--terminal-border-radius);
    border-top-right-radius: var(--terminal-border-radius);
    padding: 0 1rem;
    display: flex;
    align-items: center;
    &__dots{
      display: flex;
      >span, &:before, &:after{
        content: '';
        display: block;
        width: .875rem;
        height: .875rem;
        border-radius: 50%;
        margin-right: .25rem;
      }
      > span{
        background-color: #FDBD2E;
      }
      &:before{
        background-color: #FD6058;
      }
      &:after{
        background-color: #28CA42;
      }
    }
  }
  &__body{
    flex-grow: 1;
    width: 100%!important;
    overflow: auto;
    background: var(--terminal-bg);
    color: var(--terminal-color);
    font-family: $font-family-mono;
    font-size: 1rem;
    padding: 1rem 2rem;
    border-bottom-left-radius: var(--terminal-border-radius);
    border-bottom-right-radius: var(--terminal-border-radius);
    max-height: 65vh;
  }
  &__button{
    position: absolute;
    top: 50%;
    width: 100%;

  }
}


[data-ty] {
  display: block;
  line-height: 2;
}

[data-ty]:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
}

[data-ty="input"]:before,
[data-ty-prompt]:before {
  margin-right: 0.75em;
  color: var(--terminal-text-subtle);
}

[data-ty="input"]:before {
  content: '$';
}

[data-ty][data-ty-prompt]:before {
  content: attr(data-ty-prompt);
}

[data-ty-cursor]:after {
  content: attr(data-ty-cursor);
  font-family: monospace;
  margin-left: 0.5em;
  -webkit-animation: blink .6s infinite;
          animation: blink .6s infinite;
}


/* Cursor animation */

@-webkit-keyframes blink {
  50% {
      opacity: 0;
  }
}

@keyframes blink {
  50% {
      opacity: 0;
  }
}
